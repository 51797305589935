.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Header-project {
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}

.Hover-project {
  transition:  0.3s;
}

.Hover-project:hover {
  transform: scale(1.03,1.03);
}

.Waving-emoji {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% { transform: rotate(  0.0deg) }
  10% { transform: rotate(-10.0deg) }
  20% { transform: rotate( 12.0deg) }
  30% { transform: rotate(-10.0deg) }
  40% { transform: rotate(  9.0deg) }
  50% { transform: rotate(  0.0deg) }
  100% { transform: rotate(  0.0deg) }
}

body a {
  color: #2f89fc;
  text-decoration: none;
  background-image: linear-gradient(#2f89fc, #2f89fc);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  padding-bottom: 2px;
  transition: background-size 0.25s ease-in-out;
}

body a:hover {
  text-decoration: none;
  background-size: 100% 2px;
  color: #2f89fc !important;
}

.Nav-logo {
  font-size: 1.5em;
  letter-spacing: 3px;
  font-weight: bold;
  color: #000000 !important;
  text-decoration: none;
}

.Nav-logo:hover {
  text-decoration: none !important;
  color: #000000 !important;
  background-size: 0% !important;
}

.Nav-link {
  color: #000000 !important;
}

.Grid-margin-top {
  margin-top: 2% !important;
}

.Grid-margin-bottom {
  margin-bottom: 2% !important;
}